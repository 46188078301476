#root {
  background-color: #f0f4f7;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

html {
  height: 100%;
}

body {
  margin: 0px;
  min-height: 100%;
  position: relative;
}
